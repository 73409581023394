<template>
    <div class="container">
        <!-- 头部 -->
        <Header :tab="6"/>
        <!-- 身体 -->
        <div class="main">
            <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <img src="../../assets/imgs/xinwen.png" class="banner_img" alt />
                <div class="banner_box">

                    <!-- <div class="banner_box_r">
                        <div>新闻中心</div>
                        <div class="banner_box_r_txt">掌 / 握 / 实 / 时 / 资 / 讯 / 了 / 解 / 行 / 业 / 形 / 式</div>
                    </div> -->
                </div>
            </div>

            <!-- 主体 -->
            <div class="main_box">
                <!-- 头部 -->
                <div class="main_box_top">
                    <div class="main_box_top_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <el-carousel height="314px" @change="bannerChange">
                            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                                <div>
                                    <img :src="item.image" class="main_box_top_l_img" alt />
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div ref="banner" class="main_box_top_r wow animate__slideInUp" data-wow-delay="0.2s"
                        data-wow-iteration="1" :data-wow-offset="height_top">
                        <div class="main_box_top_r_title">{{ bannerObj.title }}</div>
                        <div class="main_box_top_r_txtBox">
                            <div class="main_box_top_r_txt">{{ bannerObj.summary }}</div>
                        </div>
                        <div class="main_box_top_r_btn">
                            <el-button type="primary" size="medium" @click="toDetails(bannerObj.id)">查看详情</el-button>
                        </div>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="case wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                    <div class="case_item" v-for="(item, index) in newsList" :key="index" @click="toDetails(item.id)">
                        <div class="case_item_top">
                            <div class="day">{{ item.day }}</div>
                            <div class="time">{{ item.created_at }}</div>
                        </div>
                        <div class="case_item_bor"></div>
                        <div class="case_item_btn">
                            <div class="case_item_btn_title">{{ item.title }}</div>
                            <div class="case_item_btn_txt">{{ item.summary }}</div>
                            <el-button type="primary" size="medium" v-for="(itm, idx) in item.tags" :key="idx"
                                @click="toNews(item.id)">{{ itm }}</el-button>
                        </div>
                    </div>
                </div>
                <!-- 分页器 -->
                <div class="paginations">
                    <el-pagination prev-text="上一页" next-text="下一页" background :pager-count="7"
                        @current-change="handleCurrentChange" :current-page="current" :page-size="size" :total="total"
                        layout="prev, pager, next"></el-pagination>
                </div>
            </div>

        </div>
        <!-- 底部 -->
        <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
    </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { articlelist10, articlelist10recommend } from "@/api/axios/axios.api";

import { WOW } from "wowjs";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            height_top: storage.height_top,
            tabIndex: '',
            tempHeight: '',
            timer: null,
            bannerList: [],
            bannerObj: {},
            newsList: [],

            isActive: 1,
            // 分页
            size: 9, //每页显示个数
            current: 1, //当前显示页
            total: 1, //总数
        };
    },
    methods: {
        // 轮播图上一页
        prevs() {
            this.$refs.carousel.prev();
        },
        // 轮播图下一页
        nexts() {
            this.$refs.carousel.next();
        },
        bannerChange(e) {
            this.bannerIndex = e
            this.bannerObj = {}
            this.bannerObj = this.bannerList[e]
        },
        // 详情
        toDetails(id) {
            this.$router.push({
                name: 'newsdetails',
                query: {
                    id: id
                }
            })
        },
        // 晓艾新闻
        toNews() { },

        // 分页器
        handleCurrentChange(e) {
            this.current = e;
            this.getNewList();
        },

        // 获取热门新闻
        async getNews() {
            let res = await articlelist10recommend({ page: this.current })
            this.bannerList = res.data.list
            this.bannerObj = res.data.list[0]
        },
        // 获取新闻列表
        async getNewList() {
            let res = await articlelist10({ page: this.current })
            this.total = Number(res.data.count);
            this.size = Number(res.data.limit);
            this.newsList = res.data.list
        },

    },
    created() { },
    mounted() {
        this.getNews()
        this.getNewList()
        this.$nextTick(() => {
            let wow = new WOW({
                live: true,
            });
            wow.init();
        });

    },
    watch: {

    },
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
    .banner {
        color: #fff;
        // height: 358px;

        .banner_img {
            // position: absolute;
            width: 100%;
            // height: 358px;
        }

        .banner_box {
            position: relative;
            padding: 0 10%;
            display: flex;

            .banner_box_r {
                position: absolute;
                top: 50px;
                left: calc(50% - 232px);
                font-size: 40px;
                text-align: center;
                line-height: 48px;
                font-weight: bold;

                .banner_box_r_txt {
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }
    }

    // 身体
    .main_box {
        position: relative;
        margin-top: -84px;
        padding: 0 10%;

        // 头部
        .main_box_top {
            margin: 0 6%;
            padding: 40px;
            box-sizing: border-box;
            background-color: #fff;
            display: flex;

            .main_box_top_l {
                // width: 540px;
                width: 40%;
                height: 314px;
                margin-right: 5%;
                box-sizing: border-box;

                .main_box_top_l_img {
                    width: 540px;
                    height: 314px;
                }

                /deep/ .is-active .el-carousel__button {
                    width: 9px;
                    height: 9px;
                    background: #000000 !important;
                    border-radius: 50%;
                }

                /deep/ .el-carousel__indicator .el-carousel__button {
                    width: 9px;
                    height: 9px;
                    background: #d2d2d2;
                    border-radius: 50%;
                }

                /deep/.new .el-carousel__indicator--horizontal {
                    padding: 0 4px;
                }
            }

            .main_box_top_r {
                flex: 1;
                color: #333333;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .main_box_top_r_title {
                    font-size: 24px;
                    margin-bottom: 20px;
                    padding-top: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }

                .main_box_top_r_txtBox {
                    flex: 1;

                    .main_box_top_r_txt {
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 6;
                    }
                }

                .el-button {
                    width: 176px;
                    height: 50px;
                    background: #000000;
                    border-color: #000;
                }
            }
        }

        // 列表
        .case {
            padding: 30px 0;
            padding-bottom: 0;
            box-sizing: border-box;
            // box-shadow: 0px 9px 11px 3px rgba(153, 153, 153, 0.07);
            display: flex;
            flex-wrap: wrap;
            align-content: space-around;

            .case_item {
                width: calc(97%/3);
                background-color: #fff;
                margin-bottom: 1.5%;
                padding: 2%;
                box-sizing: border-box;
                color: #999999;
                transition: all 0.5s;
                cursor: pointer;

                .case_item_top {
                    width: 70px;

                    .day {
                        font-size: 28px;
                        text-align: center;
                        margin-bottom: 5px;
                    }

                    .time {
                        font-size: 16px;
                    }
                }

                .case_item_bor {
                    border-bottom: 1px solid #e5e5e5;
                    margin: 23px 0;
                }

                .case_item_btn {
                    .case_item_btn_title {
                        font-weight: bold;
                        color: #333333;
                        font-size: 20px;
                        // margin-bottom: 23px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }

                    .case_item_btn_txt {
                        margin: 23px 0;
                        line-height: 26px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }

                    /deep/.el-button {
                        width: 118px;
                        height: 38px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        border-radius: 19px;
                        color: #999999;
                        transition: all 0.5s;
                    }

                    /deep/.el-button:hover {
                        width: 118px;
                        height: 38px;
                        background: #000;
                        border: 1px solid #EEEEEE;
                        border-radius: 19px;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
            }

            .case_item:nth-child(2) {
                margin: 0 1.5%;
                margin-bottom: 1.5%;
            }

            .case_item:nth-child(5) {
                margin: 0 1.5%;
                margin-bottom: 1.5%;
            }

            .case_item:nth-child(8) {
                margin: 0 1.5%;
                margin-bottom: 1.5%;
            }

            .case_item:hover {
                transition: all 0.5s;
                box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

        }

        // 分页器
        .paginations {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 30px 0;
            padding-top: 10px;
            margin: auto;

            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #000 !important;
                color: #fff !important;
            }

            .paginations_l {
                padding: 3px 15px;
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid #dcdcdc;
                margin-right: 10px;
            }

            /deep/ .btn-prev {
                background-color: #fff;
                border: 1px solid #f5f5f5;
                color: #333;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .btn-prev:hover {
                background-color: #000;
                border: 1px solid #f5f5f5;
                color: #fff;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .btn-next {
                color: #333;
                background-color: #fff;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .btn-next:hover {
                background-color: #000;
                color: #fff;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .el-pager li {
                color: #333;
                background-color: #fff;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .el-pager li:hover {
                color: #fff;

                background-color: #000;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }
        }

    }
}

@media screen and (max-width : 1399px) {
    .banner {
        min-width: 1300px;
        color: #fff;
        // height: 358px;

        .banner_img {
            // position: absolute;
            width: 100%;
            // height: 358px;
        }

        .banner_box {
            position: relative;
            padding: 0 20px;
            display: flex;

            .banner_box_r {
                position: absolute;
                top: 50px;
                left: calc(50% - 232px);
                font-size: 40px;
                text-align: center;
                line-height: 48px;
                font-weight: bold;

                .banner_box_r_txt {
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }
    }

    // 身体
    .main_box {
        position: relative;
        margin-top: -84px;
        padding: 0 20px;
        min-width: 1300px;

        // 头部
        .main_box_top {
            margin: 0 6%;
            padding: 40px;
            box-sizing: border-box;
            background-color: #fff;
            display: flex;

            .main_box_top_l {
                // width: 540px;
                width: 40%;
                height: 314px;
                margin-right: 5%;
                box-sizing: border-box;

                .main_box_top_l_img {
                    width: 540px;
                    height: 314px;
                }

                /deep/ .is-active .el-carousel__button {
                    width: 9px;
                    height: 9px;
                    background: #000000 !important;
                    border-radius: 50%;
                }

                /deep/ .el-carousel__indicator .el-carousel__button {
                    width: 9px;
                    height: 9px;
                    background: #d2d2d2;
                    border-radius: 50%;
                }

                /deep/.new .el-carousel__indicator--horizontal {
                    padding: 0 4px;
                }
            }

            .main_box_top_r {
                flex: 1;
                color: #333333;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .main_box_top_r_title {
                    font-size: 24px;
                    margin-bottom: 20px;
                    padding-top: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }

                .main_box_top_r_txtBox {
                    flex: 1;

                    .main_box_top_r_txt {
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 6;
                    }
                }

                .el-button {
                    width: 176px;
                    height: 50px;
                    background: #000000;
                    border-color: #000;
                }
            }
        }

        // 列表
        .case {
            padding: 30px 0;
            padding-bottom: 0;
            box-sizing: border-box;
            // box-shadow: 0px 9px 11px 3px rgba(153, 153, 153, 0.07);
            display: flex;
            flex-wrap: wrap;
            align-content: space-around;

            .case_item {
                width: calc(97%/3);
                background-color: #fff;
                margin-bottom: 1.5%;
                padding: 2%;
                box-sizing: border-box;
                color: #999999;
                transition: all 0.5s;
                cursor: pointer;

                .case_item_top {
                    width: 70px;

                    .day {
                        font-size: 28px;
                        text-align: center;
                        margin-bottom: 5px;
                    }

                    .time {
                        font-size: 16px;
                    }
                }

                .case_item_bor {
                    border-bottom: 1px solid #e5e5e5;
                    margin: 23px 0;
                }

                .case_item_btn {
                    .case_item_btn_title {
                        font-weight: bold;
                        color: #333333;
                        font-size: 20px;
                        // margin-bottom: 23px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }

                    .case_item_btn_txt {
                        margin: 23px 0;
                        line-height: 26px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }

                    /deep/.el-button {
                        width: 118px;
                        height: 38px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        border-radius: 19px;
                        color: #999999;
                        transition: all 0.5s;
                    }

                    /deep/.el-button:hover {
                        width: 118px;
                        height: 38px;
                        background: #000;
                        border: 1px solid #EEEEEE;
                        border-radius: 19px;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
            }

            .case_item:nth-child(2) {
                margin: 0 1.5%;
                margin-bottom: 1.5%;
            }

            .case_item:nth-child(5) {
                margin: 0 1.5%;
                margin-bottom: 1.5%;
            }

            .case_item:nth-child(8) {
                margin: 0 1.5%;
                margin-bottom: 1.5%;
            }

            .case_item:hover {
                transition: all 0.5s;
                box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

        }

        // 分页器
        .paginations {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 30px 0;
            padding-top: 10px;
            margin: auto;

            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #000 !important;
                color: #fff !important;
            }

            .paginations_l {
                padding: 3px 15px;
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid #dcdcdc;
                margin-right: 10px;
            }

            /deep/ .btn-prev {
                background-color: #fff;
                border: 1px solid #f5f5f5;
                color: #333;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .btn-prev:hover {
                background-color: #000;
                border: 1px solid #f5f5f5;
                color: #fff;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .btn-next {
                color: #333;
                background-color: #fff;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .btn-next:hover {
                background-color: #000;
                color: #fff;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .el-pager li {
                color: #333;
                background-color: #fff;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }

            /deep/ .el-pager li:hover {
                color: #fff;

                background-color: #000;
                border: 1px solid #f5f5f5;
                padding: 0 10px;
                border-radius: 5px;
            }
        }

    }
}
</style>
  